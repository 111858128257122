import { Action, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'
import {
	CalculatedAxialLengthData,
	CalculatedSphereEqData,
	NormativeTable,
	Scale,
} from '../components/content/graphs/interfaces'

import { AuthState } from './auth'
import { VideoCallState } from './azureCommunication'
import { ConnectCoreMediaContent } from './coremedia'
import { ExamState } from './exam'
import { Features } from './feature'
import { TonometerDataApi } from './instruments'
import { AnyPrescriptionType, PrescriptionContactLenses } from './prescription'
import { PhoropterSingleEye } from './refraction'
import { SettingsState } from './settings'

export type Id = string

export type NotificationType = 'success' | 'error' | 'info'

export type Notification = {
	_id: Id
	type: NotificationType
	message: string
	autoClose: boolean
	closable?: boolean
	errorId?: string
	backToLoginCta?: boolean
	errorType?: 'ui' | 'http' | 'webex'
}

export const prescriptionTypeValues = [
	'prescription-general-use',
	'prescription-contact-lenses',
	'prescription-sunglasses',
	'prescription-computer',
	'prescription-reading',
	'prescription-night',
	'prescription-sport',
	'prescription-safety',
] as const

export type PrescriptionContentType = (typeof prescriptionTypeValues)[number]

export const prescriptionTypeOtherDataValues = [
	'prescription-contact-lenses-select',
	'prescription-general-use-other',
	'prescription-sunglasses-other',
	'prescription-computer-other',
	'prescription-reading-other',
	'prescription-night-other',
	'prescription-sport-other',
	'prescription-safety-other',
] as const

export type PrescriptionContentOtherDataType =
	(typeof prescriptionTypeOtherDataValues)[number]

export const diseasesAndTreatmentsTypes = [
	'cataract',
	'glaucoma',
	'retina',
	'cornea',
	'externalDiseases',
	'refractiveErrors',
	'pediatricsStrabismus',
]

export const diseasesAndTreatmentsTypeValues = [
	'disease:cataract:nuclearCataractAnatomy',
	'disease:cataract:nuclearCataractEffect',
	'disease:cataract:corticalCataractAnatomy',
	'disease:cataract:corticalCataractEffect',
	'disease:cataract:pcoAnatomy',
	'disease:cataract:pcoEffect',
	'disease:glaucoma:acuteAngleClosureGlaucomaAnatomy',
	'disease:glaucoma:acuteAngleClosureGlaucomaEffect',
	'disease:glaucoma:chronicAngleClosureGlaucomaAnatomy',
	'disease:glaucoma:chronicAngleClosureGlaucomaEffect',
	'disease:retina:openAngleAnatomy',
	'disease:retina:openAngleEffect',
	'disease:retina:pdrAnatomy',
	'disease:retina:pdrEffect',
	'disease:retina:amdAnatomy',
	'disease:retina:amdEffect',
	'disease:retina:floatersAnatomy',
	'disease:retina:floatersEffect',
	'disease:retina:macularHoleAnatomy',
	'disease:retina:macularHoleEffect',
	'disease:retina:macularPuckerAnatomy',
	'disease:retina:macularPuckerEffect',
	'disease:retina:posteriorVitreousDetachmentAnatomy',
	'disease:retina:posteriorVitreousDetachmentEffect',
	'disease:cornea:fuchsDystrophyAnatomy',
	'disease:cornea:fuchsDystrophyEffect',
	'disease:cornea:pterygiumAnatomy',
	'disease:cornea:pterygiumEffect',
	'disease:cornea:ulcerAnatomy',
	'disease:cornea:ulcerEffect',
	'disease:cornea:pingueculaAnatomy',
	'disease:cornea:pingueculaEffect',
	'disease:cornea:keratoconusAnatomy',
	'disease:cornea:keratoconusEffect',
	'disease:externalDiseases:chelazionAnatomy',
	'disease:externalDiseases:chelazionEffect',
	'disease:externalDiseases:dryEyeAnatomy',
	'disease:externalDiseases:dryEyeEffect',
	'disease:externalDiseases:blepharitisAnatomy',
	'disease:externalDiseases:blepharitisEffect',
	'disease:refractiveErrors:myopiaAnatomy',
	'disease:refractiveErrors:myopiaEffect',
	'disease:refractiveErrors:astigmatismAnatomy',
	'disease:refractiveErrors:astigmatismEffect',
	'disease:refractiveErrors:presbyopiaAnatomy',
	'disease:refractiveErrors:presbyopiaEffect',
	'disease:refractiveErrors:hyperopiaAnatomy',
	'disease:refractiveErrors:hyperopiaEffect',
	'disease:pediatricsStrabismus:pediatricExtropiaAnatomy',
	'disease:pediatricsStrabismus:pediatricExtropiaEffect',
	'disease:pediatricsStrabismus:pediatricEsotropiaAnatomy',
	'disease:pediatricsStrabismus:pediatricEsotropiaEffect',
	'disease:pediatricsStrabismus:pediatricHypertropiaAnatomy',
	'disease:pediatricsStrabismus:pediatricHypertropiaEffect',
	'treatment:cataract:laserVsStandardNuclear',
	'treatment:cataract:nuclearCataract',
	'treatment:cataract:yagCapsulatomy',
	'treatment:glaucoma:tubeShunt',
	'treatment:glaucoma:trabulectomy',
	'treatment:glaucoma:acuteAngleClosureGlaucomaSurgery',
	'treatment:glaucoma:expressMiniShunt',
	'treatment:glaucoma:prescriptionEyeDrops',
	'treatment:glaucoma:laserTrabeculoplasty',
	'treatment:glaucoma:laserIridotomy',
	'treatment:retina:pdr',
	'treatment:retina:amdWet',
	'treatment:retina:npdr',
	'treatment:retina:vitrectomyMacularHole',
	'treatment:retina:vitrectomyMacularPucker',
	'treatment:retina:pneumaticRetinopexy',
	'treatment:retina:retinalDetachmentWithVitrectomy',
	'treatment:cornea:keratoconusCornealCrosslinking',
	'treatment:cornea:punctualPlugs',
	'treatment:refractiveErrors:allLaserLasik',
	'treatment:refractiveErrors:prk',
]

export const myopiaTypeValues = [
	'myopia-refraction-analysis',
	'myopia-axial-length-analysis',
	'myopia-refraction',
	'myopia-summary',
	'myopia-summary-zoom',
] as const

export const trendsOverTimeValues = [
	'trends-over-time',
	'trends-over-time-zoom',
] as const

export type MyopiaContentType = (typeof myopiaTypeValues)[number]

export const displayableContentTypeValues = [
	...diseasesAndTreatmentsTypeValues,
	...prescriptionTypeValues,
	...prescriptionTypeOtherDataValues,
	...myopiaTypeValues.filter(displayValue => displayValue !== 'myopia-summary'),
	...trendsOverTimeValues,
	'topography',
	'tonometer',
	'crystallineLens',
	'videoSlitLamp',
	'retinalImaging',
	'visionSimulation',
	'visualFields',
] as const

export type DisplayableContentType =
	(typeof displayableContentTypeValues)[number]

export type Eye = 'OS' | 'OD' | ''

export type DayOrNight = 'day' | 'night'

export type ExamStatus =
	| 'NotShown'
	| 'Upcoming'
	| 'IntakeFormEnded'
	| 'PreTestStarted'
	| 'PreTestFinished'
	| 'PhoropterSelected'
	| 'RefractionistStarted'
	| 'RefractionistEnded'
	| 'PhoropterFollowUpSelected'
	| 'DoctorModeSelected'
	| 'PhoropterSelectedSkipRefractionist'
	| 'DoctorStarted'
	| 'DoctorEnded'
	| 'LensTrialQuit'
	| 'DoctorEndedWithLensTrial'
	| 'Ended'
	| 'Interrupted'
	| 'Uncompleted'
	| 'Paused'
	| 'Waiting'

export type InstrumentRemoteMedia = {
	name: string
	eye: Eye
	mode?: DayOrNight
	format: string
	path?: string
	data?: string
	examStatus?: ExamStatus
	createdAt?: string
	note?: string
}

export type TopographyContent = {
	section: 'topography'
	content: {
		OS: InstrumentRemoteMedia
		OD: InstrumentRemoteMedia
	}
}

export type TonometryContent = {
	section: 'tonometer'
	data?: TonometerDataApi
	content: {
		OS: InstrumentRemoteMedia
		OD: InstrumentRemoteMedia
	}
}

export type CrystallineContent = {
	section: 'crystallineLens'
	content: {
		OS: InstrumentRemoteMedia
		OD: InstrumentRemoteMedia
	}
}

export type VideoSlitLampContent = {
	section: 'videoSLitLamp'
	content: {
		OS: InstrumentRemoteMedia
		OD: InstrumentRemoteMedia
	}
}

export type VisionSimulationContent = {
	section: 'visionSimulation'
	content: {
		both: InstrumentRemoteMedia
	}
}

export type VisualFieldsContent = {
	section: 'visualFields'
	content: {
		OS: InstrumentRemoteMedia
		OD: InstrumentRemoteMedia
	}
}

export type PrescriptionContent = {
	section: PrescriptionContentType
	content: AnyPrescriptionType | PrescriptionContactLenses
}

export type RetinalImagingContent = {
	section: 'retinalImaging'
	content: {
		OS: InstrumentRemoteMedia
		OD: InstrumentRemoteMedia
	}
}

export type DisplayableContent =
	| TopographyContent
	| PrescriptionContent
	| CrystallineContent
	| TonometryContent
	| VideoSlitLampContent
	| VisionSimulationContent
	| VisualFieldsContent
	| RetinalImagingContent

export type VideoStatus = {
	playing?: boolean
	currentTime?: number
	isFullscreen?: boolean
	startTime?: number
	endTime?: number
}

export type GraphTypeKeys =
	| 'calculatedSphereEquivalent'
	| 'calculatedAxialLength'

export type MyopiaGraphData<T> = {
	left?: T
	right?: T
	scaleY?: Scale
	ethnicity?: NormativeTable
	gender?: string
}

export type MyopiaGraphType = {
	[key in GraphTypeKeys]?: MyopiaGraphData<
		CalculatedSphereEqData | CalculatedAxialLengthData
	>
}

export type DisplayableContentState = {
	type?: DisplayableContentType
	data?: DisplayableContent
	videoStatus?: VideoStatus
}

export type RootState = {
	auth: AuthState
	settings: SettingsState
	notifications: Notification[]
	videoCall: VideoCallState
	exam: ExamState
	displayContent?: DisplayableContentState
	cursor: CursorState
	features: Features
	coremedia: ConnectCoreMediaContent
}

export type Language = 'en-US' | 'sp-US'

export type AppConfig = {
	app: {
		name: string
		version: string
	}
	region: string
	apiUrl: string
	apiUrlV2: string
	apiUrlV3: string
	mediaUrl: string
	socketUrl: string
	languages: Language[]
	environment: string
	logout: string
	login: {
		clientId: string
		redirectUri: string
		url: string
	}
}

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export type AppThunkPromise<R = void> = ThunkAction<
	Promise<R>,
	RootState,
	unknown,
	Action<string>
>

export type TDispatch = ThunkDispatch<RootState, unknown, Action<string>>

export type ErrorOrigin =
	| 'internal-api'
	| 'internal-ui'
	| 'external'
	| 'unknown'

export type SvgProps = {
	fill?: string
	fontSize?: string
	flip?: boolean
	className?: string
}

export type AddNotificationPayload = Omit<Notification, '_id'> & {
	id?: string
}

export type ErrorCallback = (error: unknown) => void

export type LoginStatus = 'not logged in' | 'logging in' | 'logged in'

export type WebexTokenResponse = {
	access_token: string
	expires_in: number
	refresh_token: string
	refresh_token_expires_in: number
}

export type CursorPosition = {
	x: number
	y: number
}

export type CursorState = {
	cursor: boolean
	position: CursorPosition
	key: number
	zoomPosition: CursorPosition
	zoomIndex: number
}

export type TrendsOverTimeInstrument = {
	key: string
	OD?: PhoropterSingleEye
	OS?: PhoropterSingleEye
}

export type instrumentType = 'sphere' | 'cyl' | 'spheq' | 'add'

export type TrendsOverTimeGraphData = {
	instrument?: instrumentType
	tot: TrendsOverTimeInstrument[]
}

export type ExternalSource = {
	code: string
	source: string
}
