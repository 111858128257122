import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../../model/model'

export const selectCallState = (state: RootState) => state.videoCall.callState

export const selectIsCallInProgress = createSelector(selectCallState, (azureCallState) => azureCallState === 'Connected')

export const selectAzureStatusIndicator = (state: RootState) =>
	state.videoCall.statusIndicator

export const selectAzureConfiguration = (state: RootState) =>
	state.videoCall.configuration

export const selectAzureDevices = (state: RootState) =>
	state.videoCall.devices