import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import Header from '../components/Header'
import { selectUserStores } from '../features/auth/selectors'
import examActions from '../features/exam/actions'
import settingsActions from '../features/settings/actions'
import { hexToRgba, pxToRem } from '../libs/style'
import { Room, Store } from '../model/store'
import Button from '../styleguide/buttons/Button'
import { PageTitle, PageWrapper } from '../styleguide/CommonComponents'
import RadioGroup from '../styleguide/RadioGroup'
import theme from '../styleguide/theme'
import LoadingPage from './LoadingPage'
import { disconnectFromScreenshare } from '../socket/screenshare/screenshareActions'
import { selectExamData } from '../features/exam/selectors'

const Wrapper = styled(PageWrapper)`
	display: flex;
	flex-direction: column;
`

const StoreAndRoom = styled.div`
	display: grid;
	grid-template-columns: 1.33fr 1fr;
	gap: ${pxToRem(theme.spacing(2.5))}rem;
	flex: 1;
`

const SelectionStore = styled(RadioGroup)`
	width: 100%;

	.radio-group__label {
		font-size: ${theme.typography.fontSizes.S};
		margin-bottom: ${pxToRem(theme.spacing(4))}rem;
		color: ${theme.palette.grey[400]};
	}

	.radio-group__options {
		background-color: ${theme.palette.background.secondary};
		border-radius: ${pxToRem(theme.shape.borderRadius)}rem;
		padding: ${pxToRem(theme.spacing(3))}rem;
		color: ${theme.palette.secondary.contrastText};
	}

	.radio-group__option {
		border-radius: ${pxToRem(theme.shape.borderRadius * 0.9)}rem;
		border: 1px solid ${hexToRgba(theme.palette.secondary.light, 0.4)};
		padding: ${pxToRem(theme.spacing(2))}rem;
		margin: 0;

		&:not(:last-child) {
			margin-bottom: ${pxToRem(theme.spacing(3))}rem;
		}

		.radio-icon {
			color: ${hexToRgba(theme.palette.secondary.light, 0.4)};
		}

		&.selected {
			border-color: ${theme.palette.secondary.light};

			.radio-icon {
				color: ${theme.palette.secondary.light};
			}
		}
	}

	.radio-group__option__sublabel {
		color: ${theme.palette.secondary.light};
		font-size: ${theme.typography.fontSizes.XS};
	}
`

const SelectionRoom = styled(SelectionStore)`
	.radio-group__option {
		padding: ${pxToRem(theme.spacing(1.5))}rem;
	}
`

const ColHeader = styled.p`
	font-family: 'Ivar Display', sans-serif;
	font-style: italic;
	font-size: 2.7rem;
`

const Footer = styled.footer`
	display: block;
	text-align: right;
	border-top: solid 1px ${theme.palette.grey[400]};
	padding: ${pxToRem(theme.spacing(4))}rem;
	margin-left: -${pxToRem(theme.spacing(theme.shape.pageMargin))}rem;
	margin-right: -${pxToRem(theme.spacing(theme.shape.pageMargin))}rem;
`

const SelectRoomPage: React.FC = () => {
	const stores = useSelector(selectUserStores)
	const exam = useSelector(selectExamData)
	const examId = exam?._id
	const enabledStores = stores?.filter(store => store.store.enableCockpit)
	const { t } = useTranslation()
	const [selectedStore, setSelectedStore] = useState<Store | null>(null)
	const [selectedRoom, setSelectedRoom] = useState<Room | null>(null)
	const dispatch = useDispatch()

	const deviceDoctorRooms = selectedStore?.stages
		// extract rooms and merge stage name into rooms
		.flatMap(({ name, rooms }) =>
			rooms.map(room => ({ ...room, stageName: name })),
		)
		.filter(({ doctorRoom }) => doctorRoom)

	return (
		<Wrapper>
			<Header showAzureItems={false} />
			<PageTitle>{t('settings.selectLocation')}</PageTitle>
			{!enabledStores ? (
				<LoadingPage message="user stores" />
			) : (
				<StoreAndRoom>
					<div>
						<ColHeader>{t('settings.office')}</ColHeader>
						<SelectionStore
							name="select-store"
							label={t('settings.selectOffice')}
							options={enabledStores.map(({ store: { _id, name } }) => ({
								value: _id,
								label: name,
							}))}
							value={selectedStore?._id || ''}
							onChange={e => {
								const value = e.target.value
								const storeCooperation = enabledStores.find(
									({ store: { _id } }) => _id === value,
								)
								setSelectedStore(storeCooperation?.store || null)
							}}
							dataTestClassName="store"
						/>
					</div>
					{deviceDoctorRooms && (
						<div>
							<ColHeader>{t('settings.examRooms')}</ColHeader>
							<SelectionRoom
								name="select-room"
								label={t('settings.selectRoom')}
								options={deviceDoctorRooms.map(({ _id, name, stageName }) => ({
									value: _id,
									label: name,
									sublabel: stageName,
								}))}
								value={selectedRoom?._id || ''}
								onChange={ev => {
									const value = ev.target.value
									setSelectedRoom(
										deviceDoctorRooms.find(({ _id }) => _id === value) || null,
									)
								}}
								dataTestClassName="room"
							/>
						</div>
					)}
				</StoreAndRoom>
			)}
			{selectedRoom && (
				<Footer>
					<Button
						data-testid="select-room-next-button"
						variant="text"
						onClick={() => {
							if (selectedStore && selectedRoom) {
								dispatch(
									settingsActions.setStoreAndRoomId({
										storeId: selectedStore._id,
										roomId: selectedRoom._id,
									}),
								)
								dispatch(examActions.clearExamData())
								examId && dispatch(disconnectFromScreenshare(examId))
							}
						}}
					>
						{t('app.next')}
					</Button>
				</Footer>
			)}
		</Wrapper>
	)
}

export default SelectRoomPage
