import { Call } from '@azure/communication-calling'
import {
	createStyles,
	InputBase,
	MenuItem,
	Select,
	Theme,
	withStyles,
} from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import videoCallActions from '../../../features/azureCommunication/actions'
import {
	selectAzureConfiguration,
	selectAzureDevices,
} from '../../../features/azureCommunication/selectors'
import { pxToRem } from '../../../libs/style'
import Modal from '../../../styleguide/Modal'
import theme from '../../../styleguide/theme'
import { isIOS, isSafari } from '../actions'

const Message = styled.p`
	font-family: 'Ivar Display';
	font-style: italic;
	font-size: ${theme.typography.fontSizes.L};
	margin-bottom: ${pxToRem(theme.spacing(1))}rem;
`
const DeviceSelector = styled.div`
	margin: ${pxToRem(theme.spacing(3))}rem 0;
`

const CustomSelect = withStyles((theme: Theme) =>
	createStyles({
		input: {
			borderRadius: 10,
			position: 'relative',
			backgroundColor: theme.palette.background.paper,
			border: '1px solid #61A4FF',
			fontSize: 12,
			padding: `${pxToRem(10)}rem ${pxToRem(26)}rem ${pxToRem(10)}rem ${pxToRem(
				12,
			)}rem`,
		},
	}),
)(InputBase)

const SettingsContainer = styled.div`
	width: ${pxToRem(450)}rem;
`

type Props = {
	setShowSettings: (show: boolean) => void
	setSettingsMenuOpen: (show: boolean) => void
}

const AzureDeviceSelector: React.FC<Props> = ({
	setShowSettings,
	setSettingsMenuOpen,
}) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const azureConfiguration = useSelector(selectAzureConfiguration)
	const azureDevices = useSelector(selectAzureDevices)

	const [temporarySelectedCameraDeviceId, setTemporarySelectedCameraDeviceId] =
		useState<string>(azureDevices.selectedCameraDeviceId)
	const [
		temporarySelectedMicrophoneDeviceId,
		setTemporarySelectedMicrophoneDeviceId,
	] = useState<string>(azureDevices.selectedMicrophoneDeviceId)
	const [
		temporarySelectedSpeakerDeviceId,
		setTemporarySelectedSpeakerDeviceId,
	] = useState<string>(azureDevices.selectedSpeakerDeviceId)

	if (window.deviceManager === undefined) {
		return null
	}

	const calls: Call[] = window.callAgent && window.callAgent.calls
	const call = calls.find(c => c.id === azureConfiguration.callId) || calls[0]

	const discardChanges = () => {
		setTemporarySelectedCameraDeviceId(azureDevices.selectedCameraDeviceId)
		setTemporarySelectedSpeakerDeviceId(azureDevices.selectedSpeakerDeviceId)
		setTemporarySelectedMicrophoneDeviceId(
			azureDevices.selectedMicrophoneDeviceId,
		)
		setShowSettings(false)
		setSettingsMenuOpen(false)
	}

	const applyChanges = () => {
		if (window.deviceManager === undefined) {
			return
		}

		if (
			temporarySelectedCameraDeviceId !== azureDevices.selectedCameraDeviceId
		) {
			window.deviceManager.getCameras().then(cameras => {
				const cameraDeviceInfo = cameras.find(
					c => c.id === temporarySelectedCameraDeviceId,
				)
				const localVideoStream = call.localVideoStreams[0]
				if (localVideoStream && cameraDeviceInfo) {
					localVideoStream.switchSource(cameraDeviceInfo)
				}
			})
		}

		if (
			temporarySelectedSpeakerDeviceId !== azureDevices.selectedSpeakerDeviceId
		) {
			window.deviceManager.getSpeakers().then(speakers => {
				const speakerDeviceInfo = speakers.find(
					s => s.id === temporarySelectedSpeakerDeviceId,
				)
				if (window.deviceManager !== undefined && speakerDeviceInfo) {
					window.deviceManager.selectSpeaker(speakerDeviceInfo)
				}
			})
		}

		if (
			temporarySelectedMicrophoneDeviceId !==
			azureDevices.selectedMicrophoneDeviceId
		) {
			window.deviceManager.getMicrophones().then(microphones => {
				const microphoneDeviceInfo = microphones.find(
					m => m.id === temporarySelectedMicrophoneDeviceId,
				)
				if (window.deviceManager !== undefined && microphoneDeviceInfo) {
					window.deviceManager.selectMicrophone(microphoneDeviceInfo)
				}
			})
		}
		dispatch(
			videoCallActions.setDevices({
				selectedCameraDeviceId: temporarySelectedCameraDeviceId,
				selectedMicrophoneDeviceId: temporarySelectedMicrophoneDeviceId,
				selectedSpeakerDeviceId: temporarySelectedSpeakerDeviceId,
			}),
		)
		setShowSettings(false)
		setSettingsMenuOpen(false)
	}

	return (
		<>
			<Modal
				open={true}
				onClose={discardChanges}
				title={t('settings.modalTitle')}
				cancelLabel={t('app.cancel')}
				confirmLabel={t('app.confirm')}
				onConfirm={applyChanges}
				Content={
					<SettingsContainer>
						<DeviceSelector>
							<Message>{t('settings.cameras')}</Message>
							<Select
								fullWidth={true}
								data-testid="camera-select"
								value={temporarySelectedCameraDeviceId}
								placeholder={'Camera'}
								input={<CustomSelect />}
								onChange={e =>
									setTemporarySelectedCameraDeviceId(e.target.value as string)
								}
							>
								{azureConfiguration.cameraDeviceOptions.map(option => (
									<MenuItem key={option.id} value={option.id}>
										{option.name}
									</MenuItem>
								))}
							</Select>
						</DeviceSelector>
						{!(isIOS || isSafari) && (
							<DeviceSelector>
								<Message>{t('settings.speakers')}</Message>
								<Select
									fullWidth={true}
									data-testid="speaker-select"
									value={temporarySelectedSpeakerDeviceId}
									placeholder={'Speaker'}
									input={<CustomSelect />}
									onChange={e =>
										setTemporarySelectedSpeakerDeviceId(
											e.target.value as string,
										)
									}
								>
									{azureConfiguration.speakerDeviceOptions.map(option => (
										<MenuItem key={option.id} value={option.id}>
											{option.name}
										</MenuItem>
									))}
								</Select>
							</DeviceSelector>
						)}
						<DeviceSelector>
							<Message>{t('settings.microphones')}</Message>
							<Select
								fullWidth={true}
								data-testid="microphone-select"
								value={temporarySelectedMicrophoneDeviceId}
								placeholder={'Nicrophone'}
								input={<CustomSelect />}
								onChange={e =>
									setTemporarySelectedMicrophoneDeviceId(
										e.target.value as string,
									)
								}
							>
								{azureConfiguration.microphoneDeviceOptions.map(option => (
									<MenuItem key={option.id} value={option.id}>
										{option.name}
									</MenuItem>
								))}
							</Select>
						</DeviceSelector>
					</SettingsContainer>
				}
			/>
		</>
	)
}

export default AzureDeviceSelector
