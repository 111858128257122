import {
	DeviceManager,
	LocalVideoStream,
	VideoStreamRenderer,
} from '@azure/communication-calling'
import React, { useEffect, useRef } from 'react'
import { VideoHost } from '../../VideoCallCommonComponent'

type StreamLocalCardProps = {
	deviceManager: DeviceManager
	selectedCameraDeviceId: string
	setCallUnknownError: (error?: string) => void
}

const StreamLocalCard: React.FC<StreamLocalCardProps> = ({
	deviceManager,
	selectedCameraDeviceId,
	setCallUnknownError,
}) => {
	const localVideoRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		let renderer: VideoStreamRenderer | undefined
		let localVideoStream: LocalVideoStream | undefined

		try {
			deviceManager.getCameras().then(cameras => {
				let cameraDeviceInfo = cameras.find(
					cameraDevice => cameraDevice.id === selectedCameraDeviceId,
				)
				if (!cameraDeviceInfo) {
					cameraDeviceInfo = cameras[0]
					if (!cameraDeviceInfo) {
						throw new Error(
							`Local camera not found, selectedCameraDeviceId: ${selectedCameraDeviceId}`,
						)
					}
				}
				localVideoStream = new LocalVideoStream(cameraDeviceInfo)
				renderer = new VideoStreamRenderer(localVideoStream)
				renderer.createView().then(view => {
					if (!localVideoRef.current) {
						return
					}
					if (localVideoRef.current.hasChildNodes()) {
						localVideoRef.current.removeChild(localVideoRef.current.children[0])
					}

					localVideoRef.current.appendChild(view.target)
				})
			})
		} catch (error: any) {
			console.warn(error)
			setCallUnknownError('Videocall, local stream error: ' + error.message)
		}

		return () => {
			if (renderer) {
				renderer.dispose()
			}
		}
	}, [deviceManager, selectedCameraDeviceId, setCallUnknownError])

	return <VideoHost ref={localVideoRef}></VideoHost>
}

export default StreamLocalCard
