import {
	Call,
	CallAgent,
	DeviceManager,
	IncomingCall,
	StartCallOptions,
} from '@azure/communication-calling'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	selectAzureCommunicationToken,
	selectStoreCockpitEnabled,
	selectUsername,
} from '../../../features/auth/selectors'
import videoCallActions from '../../../features/azureCommunication/actions'
import { selectExamId } from '../../../features/exam/selectors'
import notificationsActions from '../../../features/notifications/actions'
import {
	CALL_AGENT_INIT_STATE,
	getCallOptions,
	initAzureCallService,
} from '../actions'
import { CallEndReasonCode } from '../utils/callEndReasonCodes'
import CallCard from './AzureCall'
import AzureWarningDialog from './AzureWarningModal'
import { selectStoreId } from '../../../features/settings/selectors'
import { Device } from '../../../model/azureCommunication'
import { Wrapper } from '../../VideoCallCommonComponent'

const AzureWidget: React.FC = () => {
	const userToken = useSelector(selectAzureCommunicationToken) || ''
	const displayName = useSelector(selectUsername)
	const cockpitEnabled = useSelector(selectStoreCockpitEnabled)
	const examId = useSelector(selectExamId)
	const store = useSelector(selectStoreId)
	const dispatch = useDispatch()
	const callRef = useRef<Call>()
	const callAgentRef = useRef<CallAgent>()
	const [call, _setCall] = useState<Call>()
	const [callAgent, _setCallAgent] = useState<CallAgent>()
	const [isCallAgentInitProcedureEnded, setIsCallAgentInitProcedureEnded] =
		useState<CALL_AGENT_INIT_STATE>('IDLE')
	const [callKnownError, setCallKnownError] = useState<CallEndReasonCode>()
	const [callUnknownError, setCallUnknownError] = useState<string>()
	const [deviceManager, setDeviceManager] = useState<DeviceManager>()
	const [incomingCall, setIncomingCall] = useState<IncomingCall>()
	const [callOptions, setCallOptions] = useState<StartCallOptions>()
	const [selectedCameraDeviceId, setSelectedCameraDeviceId] =
		useState<string>('')
	const [selectedMicrophoneDeviceId, setSelectedMicrophoneDeviceId] =
		useState<string>('')
	const [selectedSpeakerDeviceId, setSelectedSpeakerDeviceId] =
		useState<string>('')
	const [cameraDeviceOptions, setCameraDeviceOptions] = useState<Device[]>([])
	const [microphoneDeviceOptions, setMicrophoneDeviceOptions] = useState<
		Device[]
	>([])
	const [speakerDeviceOptions, setSpeakerDeviceOptions] = useState<Device[]>([])
	const [showWarning, setShowWarning] = useState<boolean>(false)

	const setCall = (call?: Call) => {
		_setCall(call)
		callRef.current = call
	}
	const setCallAgent = (callAgent?: CallAgent) => {
		_setCallAgent(callAgent)
		callAgentRef.current = callAgent
	}

	const shouldInitAzureCallService =
		callAgent === undefined &&
		isCallAgentInitProcedureEnded === 'IDLE' &&
		displayName !== undefined &&
		displayName.length > 0 &&
		userToken !== undefined &&
		userToken.length > 0 &&
		cockpitEnabled !== undefined &&
		cockpitEnabled === true

	const disposeCallAgent = async () => {
		try {
			if (callAgentRef && callAgentRef.current) {
				await callAgentRef.current.dispose()
				callAgentRef.current = undefined
			}
		} catch (error: any) {
			console.warn(error)
		}
	}

	// INIT AZURE CALL SERVICE
	useEffect(() => {
		if (shouldInitAzureCallService) {
			initAzureCallService({
				userToken,
				displayName,
				callRef,
				examId,
				store,
				setCall,
				setCallUnknownError,
				setCallAgent,
				setCallKnownError,
				setDeviceManager,
				setIncomingCall,
				setIsCallAgentInitProcedureEnded,
			})
		}
	}, [displayName, userToken, shouldInitAzureCallService, examId, store])

	const shouldIGetCallOptions =
		incomingCall !== undefined &&
		callOptions === undefined &&
		deviceManager !== undefined

	// GET CALL OPTIONS
	useEffect(() => {
		if (deviceManager !== undefined && shouldIGetCallOptions === true) {
			getCallOptions({
				deviceManager,
				withVideo: true,
				setCallUnknownError,
				setSelectedCameraDeviceId,
				setSelectedMicrophoneDeviceId,
				setSelectedSpeakerDeviceId,
				setCameraDeviceOptions,
				setMicrophoneDeviceOptions,
				setSpeakerDeviceOptions,
			}).then(res => setCallOptions(res))
		}
	}, [
		shouldIGetCallOptions,
		deviceManager,
		setCallUnknownError,
		setSelectedCameraDeviceId,
	])

	const shouldIConfigureSettings =
		callOptions !== undefined &&
		deviceManager !== undefined &&
		call !== undefined

	// SET CONFIGURATION OPTIONS
	useEffect(() => {
		if (shouldIConfigureSettings === true) {
			dispatch(
				videoCallActions.setConfiguration({
					callId: call?.id || '',
					cameraDeviceOptions,
					microphoneDeviceOptions,
					speakerDeviceOptions,
				}),
			)
			dispatch(
				videoCallActions.setDevices({
					selectedCameraDeviceId,
					selectedMicrophoneDeviceId,
					selectedSpeakerDeviceId,
				}),
			)
		}
	}, [
		shouldIConfigureSettings,
		deviceManager,
		call,
		dispatch,
		selectedCameraDeviceId,
		selectedMicrophoneDeviceId,
		selectedSpeakerDeviceId,
		cameraDeviceOptions,
		microphoneDeviceOptions,
		speakerDeviceOptions,
	])

	// HANDLE ACCEPT CALL
	useEffect(() => {
		if (incomingCall && callOptions) {
			incomingCall.accept(callOptions).then(call => (window.callId = call.id))
			setIncomingCall(undefined)
		}
	}, [incomingCall, callOptions])

	// IF CALL IS SET, HANGUP, THEN DISPOSE CALLAGENT
	useEffect(() => {
		return () => {
			if (callRef && callRef.current && callRef.current.state === 'Connected') {
				callRef.current.hangUp().then(() => disposeCallAgent())
			} else {
				disposeCallAgent()
			}
			dispatch(videoCallActions.resetConfiguration())
			setIsCallAgentInitProcedureEnded('IDLE')
			dispatch(
				videoCallActions.setStatusIndicator({
					active: false,
					show: true,
					error: true,
				}),
			)
		}
	}, [dispatch])

	// MANAGE CALLAGENT INDICATOR
	useEffect(() => {
		/*
			Phase: INIT
			Show indicator -> y
			CallAgent active -> n
			Error -> n
		*/
		if (
			!callAgent &&
			['IDLE', 'START'].includes(isCallAgentInitProcedureEnded) &&
			!deviceManager
		) {
			dispatch(
				videoCallActions.setStatusIndicator({
					active: false,
					show: true,
					error: false,
				}),
			)
		}

		/*
			Phase: INIT ENDED
			Show indicator -> y
			CallAgent active -> y
			Error -> n
		*/
		if (callAgent && isCallAgentInitProcedureEnded === 'END' && deviceManager) {
			dispatch(
				videoCallActions.setStatusIndicator({
					active: true,
					show: true,
					error: false,
				}),
			)
		}

		/*
			Phase: INIT ENDED WITH CALL AGENT ERROR
			Show indicator -> y
			CallAgent active -> n
			Error -> y
		*/
		if (
			!callAgent &&
			isCallAgentInitProcedureEnded === 'ERROR' &&
			!deviceManager
		) {
			setShowWarning(true)
			dispatch(
				videoCallActions.setStatusIndicator({
					active: false,
					show: true,
					error: true,
				}),
			)
		}
	}, [callAgent, isCallAgentInitProcedureEnded, deviceManager, dispatch])

	// SHOW ALERT WITH UNKNOWN ERROR, THEN CLEAN IT
	useEffect(() => {
		if (callUnknownError) {
			dispatch(
				notificationsActions.addNotification({
					type: 'error',
					message: callUnknownError,
					autoClose: true,
					closable: true,
				}),
			)
		}
		return () => {
			setCallUnknownError(undefined)
		}
	}, [dispatch, setCallUnknownError, callUnknownError])

	// SHOW ALERT WITH KNOWN ERROR, THEN CLEAN IT
	useEffect(() => {
		if (callKnownError) {
			dispatch(
				notificationsActions.addNotification({
					type: 'error',
					message: callKnownError.message,
					autoClose: true,
					closable: true,
				}),
			)
		}
		return () => {
			setCallKnownError(undefined)
		}
	}, [dispatch, setCallKnownError, callKnownError])

	return (
		<>
			{call && deviceManager ? (
				<CallCard
					data-testid="video-call-azure"
					call={call}
					deviceManager={deviceManager}
					selectedCameraDeviceId={selectedCameraDeviceId}
					setCallUnknownError={setCallUnknownError}
					setCallOptions={setCallOptions}
				/>
			) : (
				<Wrapper data-testid="video-call-azure" />
			)}
			{showWarning === true && <AzureWarningDialog />}
		</>
	)
}

export default AzureWidget
