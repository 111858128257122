import { SetExamDataFn, CheckExamInRoomFn } from './roomSocketTypes'

type ExamMessageArgs = {
	examId: string
	setExamData: SetExamDataFn
}

export const onExamMessage = (args: ExamMessageArgs) => {
	const { setExamData, examId } = args

	if (!examId) {
		throw new Error('examId missing')
	}
	setExamData(examId)
}

type InstrumentInRoomMessageArgs = {
	storeId?: string
	examId?: string
	setExamData: SetExamDataFn
	checkExamInRoom: CheckExamInRoomFn
}

export const onInstrumentInRoomMessage = (
	args: InstrumentInRoomMessageArgs,
) => {
	const { checkExamInRoom, setExamData, examId, storeId } = args
	if (examId) {
		setExamData(examId)
		checkExamInRoom(storeId)
	}
}
