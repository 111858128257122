import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { pxToRem } from '../../../libs/style'
import Modal from '../../../styleguide/Modal'
import theme from '../../../styleguide/theme'

const Message = styled.p`
  font-family: "Ivar Display";
  font-style: italic;
  font-size: ${theme.typography.fontSizes.L};
  margin-TOP: ${pxToRem(theme.spacing(1))}rem;
  margin-bottom: ${pxToRem(theme.spacing(5))}rem;
`

const AzureWarningDialog = () => {
    const { t } = useTranslation()

    return (
        <Modal
            open={true}
            title={t('azureCommunication.warningDialogTitle')}
            confirmLabel={t('azureCommunication.reload')}
            onConfirm={() => window.location.reload()}
            Content={<Message>{t('azureCommunication.warningDialogBody')}</Message>}
            />
    )
}

export default AzureWarningDialog
