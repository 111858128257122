import {
	VideoStreamRenderer,
	VideoStreamRendererView,
} from '@azure/communication-calling'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ParticipantStreamTuple } from '../actions'
import DoctorPlaceholderCard from '../DoctorPlaceholderCard'
import {
	DoctorVideoAndShare,
	PatientLabel as DoctorLabel,
} from '../../VideoCallCommonComponent'
import { useTranslation } from 'react-i18next'

type Props = {
	remoteParticipant: ParticipantStreamTuple
	somethingInDisplay: boolean
	setCallUnknownError: (error?: string) => void
}

const StreamRemoteCard: React.FC<Props> = ({
	remoteParticipant,
	somethingInDisplay,
	setCallUnknownError,
}) => {
	const { t } = useTranslation()
	const { stream, streamRendererComponentRef } = remoteParticipant
	const [showStream, setShowStream] = useState(stream.isAvailable)
	const rendererRef = useRef<VideoStreamRenderer>()
	const viewRef = useRef<VideoStreamRendererView>()

	const disposeRenderer = useCallback(() => {
		if (rendererRef.current) {
			rendererRef.current.dispose()
			rendererRef.current = undefined
		}

		if (streamRendererComponentRef.current) {
			streamRendererComponentRef.current.hidden = true
		}
	}, [streamRendererComponentRef])

	const renderStream = useCallback(async () => {
		if (!rendererRef.current) {
			rendererRef.current = new VideoStreamRenderer(stream)
		}

		if (viewRef.current) {
			viewRef.current = undefined
		}

		viewRef.current = await rendererRef.current.createView()

		if (streamRendererComponentRef.current) {
			streamRendererComponentRef.current.hidden = false
			streamRendererComponentRef.current.appendChild(viewRef.current.target)
		}
	}, [stream, streamRendererComponentRef])

	useEffect(() => {
		stream.on('isAvailableChanged', async () => {
			try {
				if (stream.isAvailable && !rendererRef.current) {
					setShowStream(true)
					await renderStream()
				} else {
					disposeRenderer()
					setShowStream(false)
				}
			} catch (error: any) {
				console.warn(error)
				setCallUnknownError(
					'Videocall, stream remote isAvailableChanged error: ' + error.message,
				)
			}
		})

		if (stream.isAvailable) {
			try {
				setShowStream(true)
				renderStream()
			} catch (error: any) {
				console.warn(error)
				setCallUnknownError('Videocall, stream remote error: ' + error.message)
			}
		}
	}, [
		stream,
		streamRendererComponentRef,
		disposeRenderer,
		renderStream,
		setCallUnknownError,
	])

	useEffect(() => {
		return () => {
			disposeRenderer()
		}
	}, [disposeRenderer])

	const isScreenShare = stream.mediaStreamType === 'ScreenSharing'

	return !showStream ? (
		<DoctorPlaceholderCard small={somethingInDisplay} />
	) : (
		<DoctorVideoAndShare className={somethingInDisplay ? 'small' : ''}>
			<div
				ref={streamRendererComponentRef}
				className={stream.mediaStreamType}
			/>
			{!isScreenShare && <DoctorLabel>{t('call.doctor')}</DoctorLabel>}
		</DoctorVideoAndShare>
	)
}

export default StreamRemoteCard
