import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { keyframes } from 'styled-components'

import { selectExamDoctor } from '../../features/exam/selectors'
import { pxToRem } from '../../libs/style'
import theme from '../../styleguide/theme'
import { CardWrapper, PatientLabel } from '../VideoCallCommonComponent'

const Wrapper = styled(CardWrapper)`
	align-items: center;
	justify-content: center;
	background-size: cover;
	background-image: url('/assets/images/i-pad-elements-content-background-1.webp');
	background-repeat: no-repeat;

	&.small {
		.badge {
			font-size: 1.5rem;
			padding: 1rem;
			border-radius: 3rem;
			backdrop-filter: blur(${pxToRem(4)}rem);
		}
	}
`
const rippleAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(97, 164, 255, 0.1), 0 0 0 0.25em rgba(97, 164, 255, 0.1), 0 0 0 0.75em rgba(97, 164, 255, 0.1), 0 0 0 1.25em rgba(97, 164, 255, 0.1);
  }
  100% {
    box-shadow: 0 0 0 0.25em rgba(97, 164, 255, 0.1), 0 0 0 0.75em rgba(97, 164, 255, 0.1),  0 0 0 1.25em rgba(97, 164, 255, 0.1), 0 0 0 2em rgba(97, 164, 255, 0);
  }
`

export const Badge = styled.div`
	display: flex;
	justify-content: space-between;
	font-size: 6rem;
	text-transform: uppercase;
	font-family: 'Ivar Display';
	padding: 4rem;
	border-radius: 12rem;
	font-style: italic;
	backdrop-filter: blur(${pxToRem(16)}rem);
	color: ${theme.palette.common.white};
	background-image: linear-gradient(
		to bottom,
		#eee8e3,
		${theme.palette.secondary.light} 106%
	);
	background-repeat: no-repeat;
	background-size: cover;
	animation: 1s ${rippleAnimation} linear infinite;
	transition: font-size ${theme.transitions.duration.standard}ms,
		padding ${theme.transitions.duration.standard}ms,
		border-radius ${theme.transitions.duration.standard}ms;
`

const DoctorPlaceholderCard: React.FC<{ small?: boolean }> = ({ small }) => {
	const doctor = useSelector(selectExamDoctor)
	const doctorImageUrl = doctor?.imageUrl

	const { t } = useTranslation()

	const badgeStyle = {
		backgroundImage: doctorImageUrl ? `url(${doctorImageUrl})` : undefined,
		color: doctorImageUrl ? 'transparent' : undefined,
		transform: 'scale(2)',
	}

	const badgeContent = () =>
		!doctorImageUrl
			? `${doctor?.name?.charAt(0)} ${doctor?.surname?.charAt(0)}`
			: null

	return !doctor ? null : (
		<Wrapper className={small ? 'small' : ''}>
			<Badge
				data-testid="badge-doctor-placeholder"
				className="badge"
				style={badgeStyle}
			>
				{badgeContent()}
			</Badge>
			{small ? <PatientLabel>{t('call.doctor')}</PatientLabel> : null}
		</Wrapper>
	)
}

export default DoctorPlaceholderCard
