import {
	Call,
	CallState,
	DeviceManager,
	RemoteParticipant,
	StartCallOptions,
} from '@azure/communication-calling'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import videoCallActions from '../../../features/azureCommunication/actions'
import { selectInDisplayContent } from '../../../features/displayContent/selectors'
import MicIcon from '../../../styleguide/icons/MicIcon'
import {
	ButtonContainer,
	ControlButton,
	PatientLabel,
	PatientVideoAndControls,
	Wrapper,
} from '../../VideoCallCommonComponent'
import { handleCallStateChange, ParticipantStreamTuple } from '../actions'
import DoctorPlaceholderCard from '../DoctorPlaceholderCard'
import StreamLocalCard from './AzureStreamLocal'
import StreamRemoteCard from './AzureStreamRemote'

type CallCardProps = {
	call: Call
	deviceManager: DeviceManager
	selectedCameraDeviceId: string
	setCallUnknownError: (error?: string) => void
	setCallOptions: (opts?: StartCallOptions) => void
}

const CallCard: React.FC<CallCardProps> = ({
	call,
	deviceManager,
	selectedCameraDeviceId,
	setCallUnknownError,
	setCallOptions,
}) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const somethingInDisplay = !!useSelector(selectInDisplayContent)
	const [fadeInClass, setFadeInClass] = useState('')
	const [handleChangeDone, setHandleChangeDone] = useState<boolean>(false)
	const [audioEnabled, setAudioEnabled] = useState<boolean>(true)
	const [callState, setCallState] = useState<CallState>()
	const [allRemoteParticipantStreams, _setAllRemoteParticipantStreams] =
		useState<ParticipantStreamTuple[]>([])
	const refAllRemoteParticipantStreams = useRef<ParticipantStreamTuple[]>([])
	const refRemoteParticipants = useRef<RemoteParticipant[]>([])
	const disableButtons = callState !== 'Connected'

	const setAllRemoteParticipantStreams = useCallback(
		(values: ParticipantStreamTuple[]) => {
			_setAllRemoteParticipantStreams(values)
			refAllRemoteParticipantStreams.current = values
		},
		[],
	)

	const setRemoteParticipants = useCallback((values: RemoteParticipant[]) => {
		refRemoteParticipants.current = values
	}, [])

	const handleMicOnOff = async () => {
		try {
			if (!call.isMuted) {
				await call.mute()
			} else {
				await call.unmute()
			}
			setAudioEnabled(!call.isMuted)
		} catch (error: any) {
			setCallUnknownError(
				'Videocall, error during muting microphone: ' + error.message,
			)
		}
	}

	useEffect(() => {
		if (handleChangeDone === false) {
			handleCallStateChange({
				allRemoteParticipantStreams: refAllRemoteParticipantStreams,
				call,
				remoteParticipants: refRemoteParticipants,
				setAllRemoteParticipantStreams,
				setCallState,
				setHandleChangeDone,
				setRemoteParticipants,
			})
		}
	}, [
		call,
		handleChangeDone,
		refAllRemoteParticipantStreams,
		refRemoteParticipants,
		setAllRemoteParticipantStreams,
		setCallState,
		setHandleChangeDone,
		setRemoteParticipants,
	])

	useEffect(() => {
		const hideRemoteVideo =
			callState === 'Connected' &&
			allRemoteParticipantStreams &&
			allRemoteParticipantStreams.length > 1

		if (hideRemoteVideo === false) {
			const videoStream = allRemoteParticipantStreams.find(
				t => t.stream.mediaStreamType === 'Video',
			)

			if (videoStream && videoStream.streamRendererComponentRef.current) {
				videoStream.streamRendererComponentRef.current.hidden = false
			}
		}

		if (hideRemoteVideo === true) {
			const isScreenShareOnStreaming = allRemoteParticipantStreams.some(
				t =>
					t.stream.mediaStreamType === 'ScreenSharing' &&
					t.streamRendererComponentRef.current,
			)

			const videoStream = allRemoteParticipantStreams.find(
				t => t.stream.mediaStreamType === 'Video',
			)

			if (
				isScreenShareOnStreaming &&
				videoStream &&
				videoStream.streamRendererComponentRef.current
			) {
				videoStream.streamRendererComponentRef.current.hidden = true
			}
		}
	}, [allRemoteParticipantStreams, callState])

	useEffect(() => {
		setFadeInClass('fade-in')
	}, [])

	useEffect(() => {
		callState && dispatch(videoCallActions.setCallState(callState))
	}, [dispatch, callState])

	useEffect(() => {
		return () => {
			setCallOptions(undefined)
			dispatch(videoCallActions.resetConfiguration())
			dispatch(videoCallActions.setCallState('None'))
			window.callId = undefined
			window.acsLogBuffer = []
		}
	}, [dispatch, setCallOptions])

	return (
		<Wrapper className={fadeInClass}>
			{callState !== 'Connected' && (
				<DoctorPlaceholderCard small={somethingInDisplay} />
			)}
			{callState === 'Connected' && (
				<>
					{allRemoteParticipantStreams.map((remoteParticipant, index) => (
						<StreamRemoteCard
							key={index}
							remoteParticipant={remoteParticipant}
							setCallUnknownError={setCallUnknownError}
							somethingInDisplay={somethingInDisplay}
						/>
					))}
					{selectedCameraDeviceId && (
						<PatientVideoAndControls
							className={somethingInDisplay ? 'with-doctor' : ''}
						>
							<StreamLocalCard
								deviceManager={deviceManager}
								setCallUnknownError={setCallUnknownError}
								selectedCameraDeviceId={selectedCameraDeviceId}
							/>

							<ButtonContainer>
								<ControlButton
									aria-label="toggle audio"
									disabled={disableButtons}
									onClick={() => handleMicOnOff()}
									className={audioEnabled ? 'enabled' : 'disabled'}
								>
									<MicIcon />
								</ControlButton>
							</ButtonContainer>
							<PatientLabel>{t('call.patient')}</PatientLabel>
						</PatientVideoAndControls>
					)}
				</>
			)}
		</Wrapper>
	)
}

export default CallCard
