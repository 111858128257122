import { createSelector } from '@reduxjs/toolkit'
import jwt_decode from 'jwt-decode'

import { LoginToken } from '../../model/auth'
import { RootState } from '../../model/model'
import { Role, StoreCooperation } from '../../model/user'
import { selectRoomId, selectStoreId } from '../settings/selectors'

export const selectAuthToken = (state: RootState) => state.auth.token

export const selectDecodedAuthToken = createSelector(
	selectAuthToken,
	authToken => {
		if (!authToken || authToken.length === 0) {
			return undefined
		}
		const decodedToken = jwt_decode(authToken) as LoginToken
		return decodedToken
	},
)

export const selectUserRoles = createSelector(
	selectDecodedAuthToken,
	(decodedToken): Role[] => {
		return (decodedToken?.roles || []) as Role[]
	},
)

export const selectUsername = createSelector(
	selectDecodedAuthToken,
	decodedToken => {
		return decodedToken?.username?.toLowerCase() || ''
	},
)

export const selectUserEmail = createSelector(
	selectDecodedAuthToken,
	decodedToken => {
		return decodedToken?.email?.toLowerCase() || ''
	},
)

export const selectSessionId = (state: RootState) => state.auth.sessionId

export const selectUserStores = (state: RootState) => state.auth.user?.stores

export const selectAppStore = createSelector(
	selectStoreId,
	selectUserStores,
	(storeId, userStores) => {
		if (!storeId || !userStores) {
			return
		}
		return userStores.find(({ store }) => store._id === storeId)?.store
	},
)

export const selectAppRoom = createSelector(
	selectStoreId,
	selectRoomId,
	selectUserStores,
	(storeId, roomId, userStores) => {
		const room = getRoomFromStore({ storeId, roomId, userStores })

		return room
	},
)

export const selectStageId = createSelector(
	selectStoreId,
	selectRoomId,
	selectUserStores,
	(storeId, roomId, userStores) => {
		return userStores
			?.find(({ store }) => store._id === storeId)
			?.store.stages.find(
				({ rooms }) => !!rooms.find(({ _id }) => _id === roomId),
			)?._id
	},
)

export const selectAzureCommunicationToken = createSelector(
	selectStoreId,
	selectRoomId,
	selectUserStores,
	(storeId, roomId, userStores) => {
		const room = getRoomFromStore({ storeId, roomId, userStores })

		if (!room || !room.azureCommunication) {
			return
		}

		const token = room.azureCommunication.token
		return token
	},
)

export const selectStoreCockpitEnabled = createSelector(
	selectAppStore,
	store => store?.enableCockpit,
)

const getRoomFromStore = ({
	userStores,
	storeId,
	roomId,
}: {
	userStores?: StoreCooperation[]
	storeId?: string
	roomId?: string
}) => {
	if (!storeId || !roomId || !userStores) {
		return
	}

	const storeCooperation = userStores.find(({ store }) => store._id === storeId)
	if (storeCooperation === undefined) {
		return
	}

	const store = storeCooperation.store
	const stage = store.stages.find(({ rooms }) =>
		rooms.find(({ _id }) => _id === roomId),
	)

	if (!stage) {
		return
	}

	const room = stage.rooms.find(r => r._id === roomId)
	if (!room) {
		return
	}

	return room
}
