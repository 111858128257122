import { CallState } from '@azure/communication-calling'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AzureConfiguration, AzureSelectedDevice, AzureStatusIndicator, VideoCallState } from '../../model/azureCommunication'

const initialConfiguration: AzureConfiguration = {
  callId: '',
  cameraDeviceOptions: [],
  microphoneDeviceOptions: [],
  speakerDeviceOptions: [],
}

const initialSelectedDevices: AzureSelectedDevice = {
  selectedCameraDeviceId: '',
  selectedMicrophoneDeviceId: '',
  selectedSpeakerDeviceId: '',
}

const initialState: VideoCallState = {
  callState: 'None',
  configuration: initialConfiguration,
  devices: initialSelectedDevices,
  statusIndicator: {
    show: false,
		active: false,
		error: false,
  }
}

export const slice = createSlice({
  name: 'videoCall',
  initialState,
  reducers: {
    setCallState: (
      state,
      { payload }: PayloadAction<CallState>,
    ) => {
      state.callState = payload
    },
    setStatusIndicator: (
			state,
			{ payload }: PayloadAction<AzureStatusIndicator>,
		) => {
			state.statusIndicator = payload
		}, 
    setConfiguration: (
			state,
			{ payload }: PayloadAction<AzureConfiguration>,
		) => {
			state.configuration = payload
		}, 
    resetConfiguration: (
			state
		) => {
			state.configuration = initialConfiguration
		}, 
    setDevices: (
			state,
			{ payload }: PayloadAction<AzureSelectedDevice>,
		) => {
			state.devices = payload
		}, 
    resetDevices: (
			state
		) => {
			state.devices = initialSelectedDevices
		}, 
  },
})

export default slice.reducer
