import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
	selectIsCallInProgress,
	selectAzureStatusIndicator,
} from '../features/azureCommunication/selectors'
import VideoCallStateChip from '../styleguide/VideoCallStateChip'

const VideoCallState: React.FC = () => {
	const isCallInProgress = useSelector(selectIsCallInProgress)
	const statusIndicator = useSelector(selectAzureStatusIndicator)

	const { active, show, error } = statusIndicator
	const hideChip = !active && show && error

	const { t } = useTranslation()

	return hideChip ? (
		<div />
	) : (
		<VideoCallStateChip
			label={t(`azureCommunication.${isCallInProgress ? 'live' : 'onHold'}`)}
			color={isCallInProgress ? 'success' : 'secondary'}
		/>
	)
}

export default VideoCallState
