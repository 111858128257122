import React, { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import styled from 'styled-components'
import AzureWidget from '../components/azureCommunication/components/AzureWidget'
import ContentDisplay from '../components/ContentDisplay'
import Header from '../components/Header'
import PatientAndDoctorDetails from '../components/PatientAndDoctorDetails'
import { selectAppRoom, selectAppStore } from '../features/auth/selectors'
import {
	selectZoomIndex,
	selectZoomPosition,
} from '../features/cursor/selectors'
import { selectInDisplayContent } from '../features/displayContent/selectors'
import { selectExam } from '../features/exam/selectors'
import { useDateFormatter } from '../hooks/useDateFormatter'
import { useKeepAliveUser } from '../hooks/usePolling'
import { usePrepareExam } from '../hooks/usePrepareExam'
import { getCompletedSteps } from '../libs/stages'
import { pxToRem } from '../libs/style'
import { DisplayableContentType, prescriptionTypeValues } from '../model/model'
import { PageWrapper, VideoBackground } from '../styleguide/CommonComponents'
import Stepper from '../styleguide/Stepper'
import theme from '../styleguide/theme'
import useCursor from './../features/cursor/Cursor'

const WrapperHiddenOverflow = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
`

const Wrapper = styled(PageWrapper)<{
	zoom?: number
	translateX?: number
	translateY?: number
}>`
	position: relative;
	display: flex;
	flex-direction: column;
	padding-top: ${pxToRem(theme.spacing(theme.shape.pageMargin))}rem;
	${props =>
		`transform: scale(${props.zoom}, ${props.zoom}); transform-origin: ${props.translateX}% ${props.translateY}%;`}

	@media only screen and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
		padding-top: ${pxToRem(theme.spacing(3))}rem;
	}
`

const Card = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	background-color: ${theme.palette.background.default};
	border-radius: ${pxToRem(theme.shape.borderRadius)}rem;
	padding: ${pxToRem(theme.spacing(2))}rem ${pxToRem(theme.spacing(3))}rem;
	position: absolute;
	width: 100%;
	transition: bottom ${theme.transitions.duration.standard}ms,
		right ${theme.transitions.duration.standard}ms,
		top ${theme.transitions.duration.standard}ms;
	&&.lateral {
		right: 0;
	}
	min-height: 13vh;
`

const StepperCard = styled(Card)`
	flex-direction: row;
	align-items: center;
	position: relative;
	border: 1px solid white;
	background-color: rgba(255, 255, 255, 0.7);
	box-shadow: 0 ${pxToRem(20)}rem ${pxToRem(30)}rem 0 rgba(0, 0, 0, 0.01);
	min-width: 52rem;
`

const StepperCardTitle = styled.div`
	width: ${pxToRem(170)}rem;
	font-size: ${pxToRem(22)}rem;
	font-weight: 600;
	letter-spacing: -0.19px;
	line-height: ${pxToRem(20.6)}rem;
	padding-right: 3rem;
`

const LeftColumn = styled.div`
	position: relative;
	flex: 0.75;
	max-width: 75%;

	@media only screen and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
		flex: 0.7;
		max-width: 70%;
	}
`
const RightColumn = styled.div`
	position: relative;
	flex: 0.25;
	display: flex;
	flex-direction: column;
	justify-content: end;
	margin-bottom: ${pxToRem(theme.spacing(3))}rem;
	padding-left: ${pxToRem(theme.spacing(3))}rem;

	@media only screen and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
		flex: 0.3;
		max-width: 30%;
		margin-bottom: ${pxToRem(theme.spacing(2))}rem;
		padding-left: ${pxToRem(theme.spacing(2))}rem;
	}
`
const ColumnsWrapper = styled.div`
	position: relative;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	flex-direction: row;
	flex: 1;
	overflow: hidden;
`

const BackgroundImage = styled.img`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center center;
`

const hasPlainBg = (type?: DisplayableContentType) => {
	if (!type || prescriptionTypeValues.find(i => i === type)) return false
	else return true
}

const HomePage: React.FC = () => {
	const { t } = useTranslation()

	const store = useSelector(selectAppStore)
	const room = useSelector(selectAppRoom)
	const { exam, doctor, numberOfVisits, examDif } = useSelector(selectExam)
	const displayContent = useSelector(selectInDisplayContent)
	const params = useParams<{ examId?: string }>()

	const paramsExamId = params.examId || ''
	const currentExamId = room
		? room.doctorRoomLockedByExamId || ''
		: paramsExamId

	const renderButton = useCursor()
	const WrapperRef = useRef<HTMLDivElement>(null)
	const normalizedZoomPosition = useSelector(selectZoomPosition)
	const zoomPosition = {
		x: normalizedZoomPosition.x * 100,
		y: normalizedZoomPosition.y * 100,
	}
	const normalizedZoomIndex = useSelector(selectZoomIndex)
	const zoomIndex = (normalizedZoomIndex * 25 + 100) / 100

	usePrepareExam()

	const shouldRenderAzureWidget =
		exam !== undefined &&
		exam.store.enableCockpit === true &&
		exam.mode === 'remote' &&
		currentExamId.length > 0

	const type = displayContent?.type

	const steps = useMemo(
		() => getCompletedSteps(exam).map(key => t(`stepper.${key}`)),
		[exam, t],
	)

	const dateFormatter = useDateFormatter()

	const contentTypeNotShowStepper: (string | undefined)[] = [
		'topography',
		'crystallineLens',
		'tonometer',
		'videoSlitLamp',
		'retinalImaging',
		'visualFields',
		'visionSimulation',
	]

	const showStepper =
		!!exam && !!exam?.doctor && !contentTypeNotShowStepper.includes(type)

	const patientName =
		examDif?.personalInfo?.firstName ?? exam?.internalPatient.name
	const patientSurname =
		examDif?.personalInfo?.lastName ?? exam?.internalPatient.surname

	const patientDob = examDif?.personalInfo?.dateOfBirth
		? dateFormatter(
				examDif?.personalInfo?.dateOfBirth.toString().split('T')[0] + 'T00:00',
		  )
		: exam?.internalPatient.birthDate
		? dateFormatter(
				exam?.internalPatient.birthDate.toString().split('T')[0] + 'T00:00',
		  )
		: ''

	return (
		<WrapperHiddenOverflow>
			<Wrapper
				ref={WrapperRef}
				zoom={zoomIndex}
				translateX={zoomPosition.x}
				translateY={zoomPosition.y}
			>
				{hasPlainBg(type) ? (
					<BackgroundImage
						src="assets/images/i-pad-elements-content-background-1@3x.webp"
						alt=""
					/>
				) : (
					<VideoBackground />
				)}

				<Header showLogo={false} showAzureItems={shouldRenderAzureWidget} />
				<ColumnsWrapper>
					<LeftColumn>
						<ContentDisplay type={type} data={displayContent?.data} />
					</LeftColumn>
					<RightColumn>
						{exam?.doctor && (
							<>
								{shouldRenderAzureWidget && <AzureWidget />}

								<PatientAndDoctorDetails
									isDoctor={false}
									patientFirstName={patientName}
									patientSecondName={patientSurname}
									examType={exam?.examType}
									patientBirthDate={patientDob}
									numberOfVisits={numberOfVisits}
									storeName={store?.name}
								/>
								<PatientAndDoctorDetails
									isDoctor={true}
									doctorFirstName={doctor?.name}
									doctorSecondName={doctor?.surname}
								/>
							</>
						)}
					</RightColumn>
				</ColumnsWrapper>
				{showStepper && (
					<StepperCard>
						<StepperCardTitle>{t('app.completedEyeExams')}</StepperCardTitle>
						<Stepper steps={steps} maxSteps={5} />
					</StepperCard>
				)}
				{renderButton}
			</Wrapper>
		</WrapperHiddenOverflow>
	)
}

export default HomePage
