import {
	Call,
	CallAgent,
	CallClient,
	CallEndReason,
	CallState,
	CollectionUpdatedEvent,
	DeviceManager,
	IncomingCall,
	LocalVideoStream,
	RemoteParticipant,
	RemoteVideoStream,
	StartCallOptions,
} from '@azure/communication-calling'
import { AzureCommunicationTokenCredential } from '@azure/communication-common'
import { AzureLogger, setLogLevel } from '@azure/logger'
import React, { MutableRefObject } from 'react'
import { logAzureCallApi } from '../../apiCalls'
import appConfig from '../../config'
import {
	AzureLogger as AzureLoggerModel,
	Device,
} from '../../model/azureCommunication'
import { Id } from '../../model/model'
import {
	CallEndReasonCode,
	CALL_END_REASON_CODES,
} from './utils/callEndReasonCodes'

export const isIOS =
	(/iPad|iPhone|iPod/.test(navigator.platform) ||
		(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
	!window.MSStream

export const isSafari = /^((?!chrome|android).)*safari/i.test(
	navigator.userAgent,
)

export type CALL_AGENT_INIT_STATE = 'IDLE' | 'START' | 'END' | 'ERROR'
export type ParticipantStreamTuple = {
	stream: RemoteVideoStream
	participant: RemoteParticipant
	streamRendererComponentRef: React.RefObject<HTMLDivElement>
}

type LogArgs = {
	action: string
	callId?: string
	displayName: string
	examId?: Id
	error?: Error
	store: string
}

type CommonArgs = {
	callId?: string
	displayName: string
	examId?: Id
	setCallKnownError: (error?: CallEndReasonCode) => void
	setCallUnknownError: (error?: string) => void
}

type DysplayCallEndReasonArgs = {
	callEndReason: CallEndReason
	setCall: (call?: Call) => void
	setCallKnownError: (error?: CallEndReasonCode) => void
	setIncomingCall: (incomingCall?: IncomingCall) => void
}

type InitAzureCallServiceArgs = CommonArgs & {
	examId?: string
	userToken: string
	store: string
	callRef: MutableRefObject<Call | undefined>
	setCall: (call?: Call) => void
	setCallAgent: (ca?: CallAgent) => void
	setIsCallAgentInitProcedureEnded: (value: CALL_AGENT_INIT_STATE) => void
	setDeviceManager: (dm?: DeviceManager) => void
	setIncomingCall: (call?: IncomingCall) => void
}

type DeviceManagerArgs = {
	setCameraDeviceOptions: (devices: Device[]) => void
	setMicrophoneDeviceOptions: (devices: Device[]) => void
	setSelectedCameraDeviceId: (id: string) => void
	setSelectedMicrophoneDeviceId: (id: string) => void
	setSelectedSpeakerDeviceId: (id: string) => void
	setSpeakerDeviceOptions: (devices: Device[]) => void
}

type HandleCallOptionsArgs = DeviceManagerArgs & {
	deviceManager: DeviceManager
	withVideo: boolean
	setCallUnknownError: (id: string) => void
}

type HandleCallStateChangeArgs = {
	allRemoteParticipantStreams: MutableRefObject<ParticipantStreamTuple[]>
	call: Call
	remoteParticipants: MutableRefObject<RemoteParticipant[]>
	setAllRemoteParticipantStreams: (streams: ParticipantStreamTuple[]) => void
	setCallState: (state: CallState) => void
	setHandleChangeDone: (done: boolean) => void
	setRemoteParticipants: (participants: RemoteParticipant[]) => void
}

type HandleSubscribeToRemoteParticipantArgs = {
	allRemoteParticipantStreams: MutableRefObject<ParticipantStreamTuple[]>
	participant: RemoteParticipant
	remoteParticipants: MutableRefObject<RemoteParticipant[]>
	setAllRemoteParticipantStreams: (streams: ParticipantStreamTuple[]) => void
	setRemoteParticipants: (participants: RemoteParticipant[]) => void
}

const logAzure = ({ callId, examId, displayName, action, error }: LogArgs) => {
	const log: AzureLoggerModel = {
		callId: callId || '',
		examId: examId || '',
		username: displayName,
		userAgent: navigator.userAgent,
		action,
		log: window.acsLogBuffer || [],
		error: error ? error.message : '',
		platform: 'cockpit',
	}

	logAzureCallApi(log).then(() => {
		window.acsLogBuffer = []
	})
}

const displayCallEndReason = ({
	callEndReason,
	setCall,
	setCallKnownError,
	setIncomingCall,
}: DysplayCallEndReasonArgs) => {
	if (callEndReason.code !== 0 || callEndReason.subCode !== 0) {
		const issue = CALL_END_REASON_CODES.find(e => e.code === callEndReason.code)
		if (issue) {
			setCallKnownError(issue)
		}
	}
	setCall(undefined)
	setIncomingCall(undefined)
}

const subscribeToRemoteParticipant = ({
	allRemoteParticipantStreams,
	participant,
	remoteParticipants,
	setAllRemoteParticipantStreams,
	setRemoteParticipants,
}: HandleSubscribeToRemoteParticipantArgs) => {
	const rp = remoteParticipants.current.find(p => p === participant)

	if (!rp) {
		setRemoteParticipants([...remoteParticipants.current, participant])
	}

	const addToListOfAllRemoteParticipantStreams = (
		participantStreams: readonly RemoteVideoStream[],
	) => {
		if (!participantStreams || participantStreams.length === 0) {
			return
		}

		const participantStreamTuples = participantStreams.map(stream => {
			return {
				stream,
				participant,
				streamRendererComponentRef: React.createRef<HTMLDivElement>(),
			}
		})

		const updatedStreams = participantStreamTuples.reduce((prev, tuple) => {
			const exists = prev.some(t => t === tuple)

			if (
				tuple.stream.mediaStreamType === 'ScreenSharing' &&
				!tuple.stream.isAvailable
			) {
				return prev
			}

			if (!exists) {
				return [...prev, tuple]
			}

			return prev
		}, allRemoteParticipantStreams.current)

		setAllRemoteParticipantStreams(updatedStreams)
	}

	const removeFromListOfAllRemoteParticipantStreams = (
		participantStreams: RemoteVideoStream[],
	) => {
		participantStreams.forEach(streamToRemove => {
			const tupleToRemove = allRemoteParticipantStreams.current.find(
				t => t.stream === streamToRemove,
			)
			if (tupleToRemove) {
				setAllRemoteParticipantStreams(
					allRemoteParticipantStreams.current.filter(t => t !== tupleToRemove),
				)
			}
		})
	}

	const handleVideoStreamsUpdated: CollectionUpdatedEvent<
		RemoteVideoStream
	> = event => {
		addToListOfAllRemoteParticipantStreams(event.added)
		removeFromListOfAllRemoteParticipantStreams(event.removed)
	}

	addToListOfAllRemoteParticipantStreams(participant.videoStreams)
	participant.on('videoStreamsUpdated', handleVideoStreamsUpdated)
}

export const initAzureCallService = async ({
	userToken,
	displayName,
	examId,
	callRef,
	store,
	setCall,
	setCallUnknownError,
	setIsCallAgentInitProcedureEnded,
	setIncomingCall,
	setDeviceManager,
	setCallAgent,
	setCallKnownError,
}: InitAzureCallServiceArgs) => {
	try {
		window.deviceManager = undefined
		window.callId = undefined
		setIsCallAgentInitProcedureEnded('START')
		setLogLevel('info')
		const callClient = new CallClient({
			diagnostics: {
				appName: 'teleoptometry',
				appVersion: appConfig.app.version,
			},
		})
		const tokenCredential = new AzureCommunicationTokenCredential(userToken)
		const callAgent = await callClient.createCallAgent(tokenCredential, {
			displayName,
		})
		window.callAgent = callAgent
		window.acsLogBuffer = []

		AzureLogger.log = (...args) => {
			window.acsLogBuffer.push(...args)
			if (args[0].startsWith('azure:ACS:error')) {
				logAzure({
					action: 'AZURE_LOGGER_ERROR',
					store,
					callId: window.callId || '-',
					examId,
					displayName,
				})
			}
		}

		callAgent.on('callsUpdated', e => {
			e.added.forEach(call => {
				setCall(call)
			})

			e.removed.forEach(async call => {
				if (
					callRef.current &&
					callRef.current === call &&
					callRef.current.callEndReason
				) {
					displayCallEndReason({
						callEndReason: callRef.current.callEndReason,
						setCall,
						setCallKnownError,
						setIncomingCall,
					})
				}
			})
		})

		callAgent.on('incomingCall', async e => {
			const incomingCall = e.incomingCall
			if (callRef.current) {
				incomingCall.reject()
				return
			}
			setIncomingCall(incomingCall)

			incomingCall.on('callEnded', args => {
				displayCallEndReason({
					callEndReason: args.callEndReason,
					setCall,
					setCallKnownError,
					setIncomingCall,
				})
			})
		})

		setCallAgent(callAgent)
		const deviceManager = await callClient.getDeviceManager()
		const grants = await deviceManager.askDevicePermission({
			audio: true,
			video: true,
		})
		if (grants.audio === false || grants.video === false) {
			const issue = CALL_END_REASON_CODES.find(e => e.code === 701)
			if (issue) {
				setCallKnownError(issue)
			}
		}
		window.deviceManager = deviceManager
		setDeviceManager(deviceManager)
		setIsCallAgentInitProcedureEnded('END')
	} catch (error: any) {
		setIsCallAgentInitProcedureEnded('ERROR')
		setCallUnknownError(
			'Videocall, error during call agent init: ' + error.message,
		)
		logAzure({
			action: 'INIT_AZURE_CALL_SERVICE',
			store,
			callId: '',
			examId,
			displayName,
			error,
		})
	}
}

export const getCallOptions = async ({
	deviceManager,
	withVideo,
	setCameraDeviceOptions,
	setCallUnknownError,
	setSelectedCameraDeviceId,
	setSelectedMicrophoneDeviceId,
	setSelectedSpeakerDeviceId,
	setMicrophoneDeviceOptions,
	setSpeakerDeviceOptions,
}: HandleCallOptionsArgs) => {
	await deviceManager.askDevicePermission({
		audio: true,
		video: true,
	})

	const callOptions: StartCallOptions = {
		videoOptions: {
			localVideoStreams: undefined,
		},
		audioOptions: {
			muted: false,
		},
	}

	const warnings = {
		camera: '',
		speaker: '',
		microphone: '',
	}
	try {
		const cameras = await deviceManager.getCameras()
		const cameraDevice = cameras[0]

		const camerasOptions = cameras.map(camera => {
			return { id: camera.id, name: camera.name }
		})
		setSelectedCameraDeviceId(cameraDevice?.id)
		setCameraDeviceOptions(camerasOptions)

		if (withVideo) {
			if (!cameraDevice || cameraDevice?.id === 'camera:') {
				throw new Error('No camera devices found.')
			}
			if (cameraDevice) {
				callOptions.videoOptions = {
					localVideoStreams: [new LocalVideoStream(cameraDevice)],
				}
			}
		}
	} catch (error: any) {
		warnings.camera = error.message
	}

	try {
		// Known issue: https://bit.ly/3GEi9fF
		const speakers = await deviceManager.getSpeakers()
		const speakerDevice = speakers[0]

		if (!speakerDevice || speakerDevice.id === 'speaker:') {
			throw new Error('No speaker devices found.')
		}

		await deviceManager.selectSpeaker(speakerDevice)
		const speakersOptions = speakers.map(speaker => {
			return { id: speaker.id, name: speaker.name }
		})
		setSelectedSpeakerDeviceId(speakerDevice.id)
		setSpeakerDeviceOptions(speakersOptions)
	} catch (error: any) {
		if (!isIOS && !isSafari) {
			warnings.speaker = error.message
		}
	}

	try {
		const microphones = await deviceManager.getMicrophones()
		const microphoneDevice = microphones[0]
		if (!microphoneDevice || microphoneDevice.id === 'microphone:') {
			throw new Error('No microphone devices found.')
		}
		await deviceManager.selectMicrophone(microphoneDevice)
		const microphonessOptions = microphones.map(microphone => {
			return { id: microphone.id, name: microphone.name }
		})
		setSelectedMicrophoneDeviceId(microphoneDevice.id)
		setMicrophoneDeviceOptions(microphonessOptions)
	} catch (error: any) {
		warnings.microphone = error.message
	}

	if (warnings.camera || warnings.microphone || warnings.speaker) {
		const msg = `${warnings.camera || ''} ${warnings.microphone || ''} ${
			warnings.speaker || ''
		}`
		setCallUnknownError('Videocall, error selecting devices: ' + msg)
	}

	return callOptions
}

export const handleCallStateChange = ({
	call,
	allRemoteParticipantStreams,
	remoteParticipants,
	setAllRemoteParticipantStreams,
	setCallState,
	setHandleChangeDone,
	setRemoteParticipants,
}: HandleCallStateChangeArgs) => {
	call.on('stateChanged', () => {
		setCallState(call.state)
	})
	call.remoteParticipants.forEach(participant =>
		subscribeToRemoteParticipant({
			allRemoteParticipantStreams,
			participant,
			remoteParticipants,
			setAllRemoteParticipantStreams,
			setRemoteParticipants,
		}),
	)

	call.on('remoteParticipantsUpdated', e => {
		e.added.forEach(participant =>
			subscribeToRemoteParticipant({
				allRemoteParticipantStreams,
				participant,
				remoteParticipants,
				setAllRemoteParticipantStreams,
				setRemoteParticipants,
			}),
		)

		e.removed.forEach(p => {
			const remoteParticipantsUpdated = remoteParticipants.current.filter(
				remoteParticipant => remoteParticipant !== p,
			)
			setRemoteParticipants(remoteParticipantsUpdated)
		})
	})

	setHandleChangeDone(true)
}
