import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'

import { pxToRem } from '../../libs/style'
import theme from '../theme'
import FirstLetterCapital from '../typography/FirstLetterCapital'

const MyButton = styled.button`
	border-radius: 10em;
	text-transform: lowercase;
	padding: ${pxToRem(theme.spacing(1))}rem ${pxToRem(theme.spacing(3))}rem;

	&.MuiButton-outlined {
		padding: ${pxToRem(theme.spacing(0.7))}rem ${pxToRem(theme.spacing(2))}rem;

		.MuiButton-label {
			font-weight: bold;
		}
	}
`

const TextButton = styled.button`
	color: ${theme.palette.grey[400]};
	text-transform: uppercase;
	padding: 0;
	min-width: 0;

	&:hover {
		background-color: transparent;
	}
`

const Label = styled(FirstLetterCapital)``

type Props = {
	variant?: 'text' | 'outlined' | 'contained'
	loading?: boolean
	onClick?: MouseEventHandler<HTMLButtonElement>
	disabled?: boolean
}

const Button: React.FC<Props> = ({ children, ...props }) => {
	return props.variant === 'text' ? (
		<TextButton {...props}>{children}</TextButton>
	) : (
		<MyButton {...props}>
			<Label className="button--label">{children}</Label>
		</MyButton>
	)
}

export default Button
